<template lang="pug">
v-container(fluid)
  v-row
    v-col.ma-0.pa-0
      v-carousel(
        cycle,
        height="600",
        dark,
        hide-delimiter-background,
        show-arrows-on-hover,
        interval="4000"
      )
        v-carousel-item(v-for="(slide, i) in slides", :key="i")
          v-sheet(:color="slide.color", height="100%")
            v-row.pa-4.fill-height(align="center", justify="center")
              v-col.mx-4
                .text-h2.pa-4 {{ slide.title }}
                .caption.pa-4 {{ slide.text }}
  v-row 
    v-col.text-center
      v-btn(color="green", large, dark, @click="play")
        | {{ $t('btn.startGame') }}
</template>
<script>
export default {
  title: function () {
    return this.$t("title");
  },
  data() {
    return {
      slides: [
        {
          color: "green",
          title: "Get to know yourself",
          text: "Find out about yourself by simply answering questions from multiple categories. The Shaker will turn it into your personal profile.",
        },
        {
          color: "light-green",
          title: "Collect points",
          text: "Each answer is also a point that brings you closer to get a rewards!",
        },
        {
          color: "lime",
          title: "Match with others",
          text: "We will use your answers to compare with other players, when have enough points we will make you a match.",
        },
      ],
    };
  },

  methods: {
    play() {
      this.$router.push({ name: "gameTimeline" }).catch(() => {});
    },
  },
};
</script>